
@font-face {
    font-family: 'ShantellSans';
    src: url('../fonts/ShantellSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ShantellSans';
    src: url('../fonts/ShantellSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'ShantellSans';
    src: url('../fonts/ShantellSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

body, h1, h2, h3, h4, h5, h6, p, span {
    font-family: 'ShantellSans'
}

.linkStyle {
    cursor: pointer;
}

.content-container {
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
}

.product-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 50px;
}

.header-container {
    width: 500px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header-row-container {
    width: 500px;
    height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.about-us-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: left;
}

.demo-video-container {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
}